<template>
  <div>
    <div>
      <header
        class="ts-contact-form-container__header ts-text-gray-4 text-center"
      >
        <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
          Let's get you started
        </h1>
        <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-07">
          Please fill out your details
        </p>
      </header>

      <form>
        <div class="ts-icon-overlay-right mb-2">
          <input
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            class="form-control form-control-lg rounded-pill"
            id="Password"
            placeholder="Password"
          />
          <span
            role="button"
            class="ts-icon-overlay-right__button border-0 p-0 lh-1 btn"
            @click="togglePasswordVisibility"
          >
            <Icon :name="showPassword ? 'eye-off' : 'eye-on'" />
          </span>
        </div>
        <div class="ts-icon-overlay-right mb-08">
          <input
            v-model="confirmPassword"
            :type="confirmShowPassword ? 'text' : 'password'"
            class="form-control form-control-lg rounded-pill"
            id="Confirm Password"
            placeholder="Confirm Password"
          />

          <span
            role="button"
            class="ts-icon-overlay-right__button border-0 p-0 lh-1 btn"
            @click="confirmTogglePasswordVisibility"
          >
            <Icon :name="confirmShowPassword ? 'eye-off' : 'eye-on'" />
          </span>
        </div>

        <div class="mb-07">
          <p class="ts-fs-7 px-3">Please verify your email</p>
          <div class="ts-custom-email-input">
            <input
              type="email"
              class="form-control form-control-lg rounded-pill"
              id="EmailAddressHelp"
              aria-describedby="EmailAddressHelp"
              placeholder="Email Address"
            />
            <button
              class="ts-btn ts-btn-secondary rounded-pill d-flex align-items-center justify-content-center"
            >
              Send code
            </button>
          </div>
        </div>
        <div class="px-1 px-sm-3 mb-02 mb-lg-07 mt-4 mt-lg-0">
          <div
            class="form-check d-flex justify-content-center justify-content-sm-start gap-2"
          >
            <input
              class="ts-form-check-input form-check-input rounded-circle mb-1"
              type="checkbox"
              name="sameWhatsNumber"
              id="sameWhatsNumber"
              value="option1"
            />
            <label class="ts-fs-7-ii form-check-label" for="sameWhatsNumber">
              I have read & accept the
              <a class="ts-text-primary-1" href="#"> terms of service</a>
            </label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Icon from "../../../assets/icons/index.vue";
export default {
  name: "BrandsForm2",
  components: {
    Icon,
  },

  data() {
    return {
      password: "",
      showPassword: false,
      confirmPassword: "",
      confirmShowPassword: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    confirmTogglePasswordVisibility() {
      this.confirmShowPassword = !this.confirmShowPassword;
    },
  },
};
</script>
<style lang="scss" scoped>
.ts-icon-overlay-right {
  position: relative;

  &__button {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
