<template>
  <section class="ts-faq">
    <div class="ts-faq__content">
      <div class="ts-container-fluid container-fluid">
        <h1 class="ts-faq__title ts-fs-1 ts-text-gray-5 fw-bold text-center">
          Frequently Asked Questions -
          <span class="ts-text-primary-1"> Brands </span>
        </h1>
        <div class="accordion accordion-flush" id="tsFaqAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button ts-fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse-1"
                aria-expanded="false"
                aria-controls="flush-collapse-1"
              >
                What are the different plans available for brands?
              </button>
            </h2>
            <div
              id="flush-collapse-1"
              class="accordion-collapse collapse"
              data-bs-parent="#tsFaqAccordion"
            >
              <div class="accordion-body ts-fs-5">
                Our platform offers various plans tailored to suit your brand's
                needs. We have flexible subscription options that provide access
                to different features and services. Please visit our Pricing
                page for detailed information on each plan.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button ts-fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse-2"
                aria-expanded="false"
                aria-controls="flush-collapse-2"
              >
                How does the payment process work?
              </button>
            </h2>
            <div
              id="flush-collapse-2"
              class="accordion-collapse collapse"
              data-bs-parent="#tsFaqAccordion"
            >
              <div class="accordion-body ts-fs-5">
                Our platform offers various plans tailored to suit your brand's
                needs. We have flexible subscription options that provide access
                to different features and services. Please visit our Pricing
                page for detailed information on each plan.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button ts-fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse-3"
                aria-expanded="false"
                aria-controls="flush-collapse-3"
              >
                Can I create multiple campaigns simultaneously?
              </button>
            </h2>
            <div
              id="flush-collapse-3"
              class="accordion-collapse collapse"
              data-bs-parent="#tsFaqAccordion"
            >
              <div class="accordion-body ts-fs-5">
                Our platform offers various plans tailored to suit your brand's
                needs. We have flexible subscription options that provide access
                to different features and services. Please visit our Pricing
                page for detailed information on each plan.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button ts-fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse-4"
                aria-expanded="false"
                aria-controls="flush-collapse-4"
              >
                Are there any additional services available to enhance my
                campaign's performance?
              </button>
            </h2>
            <div
              id="flush-collapse-4"
              class="accordion-collapse collapse"
              data-bs-parent="#tsFaqAccordion"
            >
              <div class="accordion-body ts-fs-5">
                Our platform offers various plans tailored to suit your brand's
                needs. We have flexible subscription options that provide access
                to different features and services. Please visit our Pricing
                page for detailed information on each plan.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button ts-fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse-5"
                aria-expanded="false"
                aria-controls="flush-collapse-5"
              >
                How do I select the right influencers for my campaign?
              </button>
            </h2>
            <div
              id="flush-collapse-5"
              class="accordion-collapse collapse"
              data-bs-parent="#tsFaqAccordion"
            >
              <div class="accordion-body ts-fs-5">
                Our platform offers various plans tailored to suit your brand's
                needs. We have flexible subscription options that provide access
                to different features and services. Please visit our Pricing
                page for detailed information on each plan.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button ts-fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse-6"
                aria-expanded="false"
                aria-controls="flush-collapse-6"
              >
                Can I track the performance and ROI of my campaigns?
              </button>
            </h2>
            <div
              id="flush-collapse-6"
              class="accordion-collapse collapse"
              data-bs-parent="#tsFaqAccordion"
            >
              <div class="accordion-body ts-fs-5">
                Our platform offers various plans tailored to suit your brand's
                needs. We have flexible subscription options that provide access
                to different features and services. Please visit our Pricing
                page for detailed information on each plan.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FaqSection",
};
</script>

<style scoped lang="scss">
.ts-faq {
  background-color: #fcfcfc;

  &__content {
    max-width: 1163px;
    margin: auto;
    padding: clamp(120px, 13vw, 216px) 0 clamp(60px, 7vw, 90px) !important;
  }

  &__title {
    margin-bottom: clamp(4.6875rem, 10vw, 110px);
  }
}

.accordion {
  --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28.1" height="16.527" viewBox="0 0 28.1 16.527"><path id="Path_937" data-name="Path 937" d="M0,0,10.368,10.492,21.029,0" transform="translate(3.536 3.535)" fill="none" stroke="%2300a4b6" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/></svg>');
  --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28.1" height="16.527" viewBox="0 0 28.1 16.527"><path id="Path_937" data-name="Path 937" d="M0,0,10.368,10.492,21.029,0" transform="translate(3.536 3.535)" fill="none" stroke="%2300a4b6" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/></svg>');

  .collapsed,
  &-item {
    background-color: transparent !important;
  }

  &-button {
    padding-inline: 0;
    box-shadow: none;
    color: #404040 !important;

    &:not(.collapsed) {
      color: var(--bs-accordion-active-color);
      background-color: transparent !important;
      box-shadow: 0 !important;
      outline: none !important;
    }
  }

  &-body {
    padding-inline: 0;
    color: #606060 !important;
  }

  &-button {
    font-weight: 600;
  }
}
</style>
