<template>
  <section class="ts-unlock-benifits">
    <div class="row align-items-center">
      <div class="col-lg-7 text-center text-lg-start">
        <h1 class="ts-fs-1 ts-text-gray-5 fw-bold mb-0">
          As a <span class="ts-text-primary-1"> creator</span>
        </h1>
        <h1 class="ts-fs-1-ii ts-text-gray-5 fw-normal mb-04 mb-lg-06">
          unlock exclusive benefits
        </h1>
        <p class="ts-fs-5 fw-light mb-06 mb-lg-07-ii">
          Enjoy complimentary access to a wide range of offers including
          dinners, brunches, spa and wellness centers, salons, hotel stays,
          weekend getaways, shopping centers, and more, all while growing your
          social media presence.
        </p>
        <div class="d-none d-lg-flex flex-column flex-sm-row gap-2">
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-primary rounded-pill"
            to="/creators"
          >
            Sign up
          </router-link>
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill"
            to="/creators"
          >
            Learn more
          </router-link>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          class="w-100 mb-06 mb-lg-0"
          src="/images/Home/as-a-creator.png"
          alt="..."
        />

        <div
          class="d-flex d-lg-none align-items-center justify-content-center justify-content-lg-start flex-column flex-sm-row gap-2"
        >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-primary rounded-pill text-center"
            to="/creators"
            >Sign up</router-link
          >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill text-center"
            to="/creators"
            >Learn more</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UnlockBenifits",
};
</script>
