<template>
  <div class="ts-team-card ts-shadow-1 overflow-hidden">
    <div class="ts-team-card__header">
      <img
        class="ts-phone-img w-100"
        src="/images/Team/departments/1.jpg"
        alt="..."
      />
    </div>
    <div class="ts-team-card__body text-center ts-text-gray-3">
      <h6 class="ts-team-card__subtitle fw-semibold">{{ name }}</h6>
      <h6 class="ts-team-card__subtitle mb-0">{{ department }}</h6>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamCard",

  props: {
    name: String,
    department: String,
  },
};
</script>
<style lang="scss" scoped>
.ts-team-card {
  background-color: white;
  border-radius: 1.5625rem;

  &__body {
    padding-block: 0.8125rem 1rem;
    @media (max-width: 991px) {
      padding-block: 0.875rem 1.1875rem;
    }
  }
  &__title {
    font-size: 18px;
    @media (max-width: 991px) {
      font-size: 1.5625rem;
    }
  }
  &__subtitle {
    font-size: 15px;
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }
}
</style>
