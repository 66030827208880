<template>
  <img
    class="ts-career-hero-img w-100 mb-08 mb-lg-10 d-none d-sm-block"
    src="/images/career/hero.jpg"
    alt="..."
  />
  <img
    class="ts-career-hero-img w-100 mb-08 mb-lg-10 d-sm-none"
    src="/images/career/hero-sm.jpg"
    alt="..."
  />

  <section>
    <div class="ts-container-fluid container-fluid">
      <header class="ts-career__header text-center mb-08 mb-lg-12">
        <h1
          class="ts-heading ts-fs-1 ts-text-gray-3 fw-bold text-center mb-04 mb-lg-07"
        >
          We are fueled by a collective passion for what we do
        </h1>

        <p class="ts-desc ts-fs-5 fw-light mb-05 mb-lg-07">
          We value a positive and inclusive work culture where everyone's voice
          is heard, ideas are encouraged, and achievements are celebrated.
        </p>
      </header>
    </div>

    <div class="ts-career-cards-group-wrapper">
      <div class="ts-container-fluid container-fluid">
        <div class="ts-career-cards-group">
          <div class="" v-for="card in cards" :key="card.id">
            <JobCard :title="card.title" :desc="card.desc" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Modal -->
  <div
    class="modal fade"
    id="applicationModal"
    tabindex="-1"
    aria-labelledby="applicationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-0">
          <ApplicationForm />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JobCard from "../components/Career/JobCard.vue";
import ApplicationForm from "../components/Career/ApplicationForm.vue";

export default {
  name: "CareerPage",
  components: {
    JobCard,
    ApplicationForm,
  },

  data() {
    return {
      cards: [
        {
          id: 1,
          title: "TikTok Creator",
          desc: "We're seeking a talented TikTok Creator to produce engaging and viral content.",
        },
        {
          id: 2,
          title: "Sales Representative",
          desc: "Join our team to drive growth through exceptional client relationships and sales strategies.",
        },
        {
          id: 3,
          title: "Content Moderator",
          desc: " Ensure a safe online environment by reviewing user-generated content and enforcing guidelines.",
        },
        {
          id: 4,
          title: "Campaign Manager",
          desc: "We're looking for a Social Media Campaign Manager. Drive campaigns, manage content, and analyze performance.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.ts-career-hero-img {
  @media (max-width: 991px) {
    min-height: 360px;
    object-fit: cover;
  }
}

.modal-content {
  background-color: transparent;
  border: 0;
  max-width: 476px;
  justify-content: center;
}

.ts-career__header {
  .ts-heading {
    max-width: 920px;
    margin-inline: auto;
  }

  .ts-desc {
    max-width: 990px;
    margin-inline: auto;
  }
}

.ts-career-cards-group {
  max-width: 1228px;
  margin: auto;

  &-wrapper {
    padding-block: 15px 90px;

    @media (min-width: 992px) {
      padding-block: 90px;
    }
    background-color: #fcfcfc;
  }

  @media (max-width: 991px) {
    & > div {
      border-bottom: 1px solid #e1e1e1;
    }
  }
  @media (min-width: 992px) {
    & > div {
      &:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }
}
</style>
