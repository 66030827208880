<template>
  <div>
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center"
    >
      <h1 class="ts-fs-4 fw-bold text-center mb-01">
        Add your social handle/s
      </h1>
      <p class="ts-fs-7 fw-light mb-05 mb-lg-10">
        Minimum 1 social handle is required
      </p>
    </header>
    <form>
      <div class="ts-social-media-handle-cards-group mb-5">
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
        >
          <div class="ts-social-media-handle ts-social-media-handle--active">
            <Icon name="instagram" />
          </div>
          <p class="mb-0">Instagram</p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
        >
          <div class="ts-social-media-handle">
            <Icon name="tiktok" />
          </div>
          <p class="mb-0">Tiktok</p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
        >
          <div class="ts-social-media-handle">
            <Icon name="snapchat" />
          </div>
          <p class="mb-0">Snapchat</p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
        >
          <div class="ts-social-media-handle">
            <Icon name="twitch" />
          </div>
          <p class="mb-0">Twitch</p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
        >
          <div class="ts-social-media-handle">
            <Icon name="youtube" />
          </div>
          <p class="mb-0">Youtube</p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
        >
          <div class="ts-social-media-handle">
            <Icon name="twitter" />
          </div>
          <p class="mb-0">Twitter</p>
        </div>
      </div>
      <div class="px-1 px-sm-3 mb-05">
        <div
          class="form-check d-flex justify-content-center justify-content-sm-start gap-2"
        >
          <input
            class="ts-form-check-input form-check-input rounded-circle mb-1"
            type="checkbox"
            name="sameWhatsNumber"
            id="sameWhatsNumber"
            value="option1"
          />
          <label class="ts-fs-7-ii form-check-label" for="sameWhatsNumber">
            I have read & accept the
            <a class="ts-text-primary-1" href="#"> terms of service</a>
          </label>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Icon from "../../../assets/icons/index.vue";
export default {
  name: "CreatorForm4",
  components: {
    Icon,
  },

  data() {
    return {};
  },
};
</script>
<style lang="scss">
.ts-social-media-handle-cards-group {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  gap: 25px 34px;
  justify-content: center;
  max-width: 278px;
  margin: auto;
  // flex-row-gap: 35px;
}
.ts-social-media-handle {
  width: 68px;
  height: 65px;
  background-color: #f6f6f6;
  display: grid;
  place-items: center;
  border-radius: 12px;
  margin-bottom: 10px;
  cursor: pointer;

  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  svg {
    ellipse,
    path {
      fill: #777777 !important;
    }
  }

  #twitter {
    width: 33px !important;
    height: 27px !important;
  }
  #instagram {
    width: 34px !important;
    height: 34px !important;
  }

  &--active {
    background-color: #00a4b6;
    svg {
      ellipse,
      path {
        fill: white !important;
      }
    }
  }
  &-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
    color: #777777;
  }
}
</style>
