import { createApp } from "vue";
import App from "./App.vue";

import VOtpInput from "vue3-otp-input";

import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.bundle.js";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";

import router from "./router/index.js";

createApp(App)
  .component("v-otp-input", VOtpInput)
  .use(router)
  .use(bootstrap)
  .mount("#app");
// createApp(App).use(router).mount("#app");
