<template>
  <div class="ts-career-job-card d-flex justify-content-between">
    <div class="ts-text-gray-3-b">
      <h6 class="ts-fs-4 fw-semibold mb-05 text-center text-md-start">
        {{ title }}
      </h6>
      <h6 class="ts-fs-6 text-center text-md-start mb-07 mb-lg-11">
        {{ desc }}
      </h6>
      <div
        class="d-flex justify-content-center justify-content-md-start align-items-center flex-wrap gap-2 gap-md-3"
      >
        <span class="ts-career-tag">
          <Icon name="location-pin" /> <span> 100% remote</span>
        </span>
        <span class="ts-career-tag">
          <Icon name="Clock" /> <span> Full time</span>
        </span>
        <div class="d-block d-md-none">
          <button
            class="d-flex align-items-center gap-1 btn fw-bold ts-fs-5 border-0 px-1"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#applicationModal"
          >
            <span class="text-decoration-underline ts-text-primary-1">
              Apply
            </span>
            <Icon name="arrow-top-right" />
          </button>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block">
      <button
        class="d-flex align-items-center gap-1 gap-sm-3 btn fw-bold ts-fs-5 border-0"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#applicationModal"
      >
        <span class="text-decoration-underline ts-text-primary-1"> Apply </span>
        <Icon name="arrow-top-right" />
      </button>
    </div>
  </div>
</template>
<script>
import Icon from "../../assets/icons/index.vue";

export default {
  name: "JobCard",
  components: {
    Icon,
  },
  props: {
    title: String,
    desc: String,
  },
};
</script>
<style lang="scss">
.ts-career-job-card {
  padding-block: 1.9375rem;
}

.ts-career-tag {
  color: #404040;
  padding: clamp(0.375rem, 1vw, 0.625rem) clamp(0.9375rem, 2vw, 1.8rem);
  border-radius: 50rem;
  line-height: 122%;
  display: flex;
  align-items: center;
  gap: clamp(7px, 1.2vw, 0.8125rem);
  border: 1px solid #6a6a6a;
  font-size: clamp(13px, 3vw, 23px);
  line-height: 100%;

  svg {
    width: clamp(11px, 3vw, 20px);
  }

  @media (max-width: 575px) {
    padding: 1px 0.9375rem;
    height: 35px;
  }
}
</style>
