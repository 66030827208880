<template>
  <div class="ts-contact m-0">
    <div class="ts-contact__content">
      <div class="ts-contact-form-container ts-shadow-1">
        <header class="ts-contact-form-container__header text-center">
          <h1
            class="ts-fs-4-ii ts-text-primary-1 fw-bold text-center mb-05 mb-lg-06"
          >
            Job Application
          </h1>
          <p class="ts-fs-7 fw-light mb-06 mb-lg-07">
            Interested in joining our team? <br />
            Please complete the application below
          </p>
        </header>

        <form>
          <div class="row row-cols-2">
            <div class="mb-2 pe-1">
              <input
                type="text"
                class="form-control form-control-lg rounded-pill"
                id="firstName"
                placeholder="First Name"
              />
            </div>
            <div class="mb-2 ps-1">
              <input
                type="text"
                class="form-control form-control-lg rounded-pill"
                id="lastName"
                placeholder="Last Name"
              />
            </div>
          </div>

          <div class="mb-2">
            <input
              type="email"
              class="form-control form-control-lg rounded-pill"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Email"
            />
          </div>

          <div class="ts-form-control-group d-flex mb-2">
            <div class="">
              <select
                class="form-select form-select-lg rounded-pill ts-phone"
                aria-label="Default select example"
              >
                <option selected>+971</option>
                <option value="1">+972</option>
                <option value="2">+973</option>
                <option value="3">+974</option>
              </select>
            </div>
            <div class="flex-fill">
              <input
                type="tel"
                class="form-control form-control-lg rounded-pill"
                id="tsPhone"
                aria-describedby="PhoneHelp"
                placeholder="Phone"
              />
            </div>
          </div>
          <div class="mb-2">
            <select
              class="form-select form-select-lg rounded-pill"
              aria-label="Default select example"
            >
              <option selected>Current location</option>
              <option value="1">country-1</option>
              <option value="2">country-2</option>
              <option value="3">country-3</option>
            </select>
          </div>
          <div class="mb-06 mb-lg-10">
            <div class="ts-attach-cv input-group mb-2" role="button">
              <label
                class="input-group-text justify-content-center gap-3 align-items-center"
                for="uploadCv"
              >
                <Icon name="Complete" /> Upload
              </label>
            </div>
            <input
              @change="handleFileChange"
              type="file"
              class="form-control"
              id="uploadCv"
            />

            <p>
              {{ cvLocation }}
            </p>
          </div>
          <button
            type="submit"
            class="ts-btn ts-fs-7-ii ts-btn-secondary w-100 rounded-pill"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "../../assets/icons/index.vue";

export default {
  name: "ApplicationForm",
  components: {
    Icon,
  },

  data: function () {
    return {
      cvLocation: "",
    };
  },

  methods: {
    handleFileChange(event) {
      let filePath = event.target.value.split("fakepath\\")[1];

      if (filePath) {
        this.cvLocation = filePath;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.ts-attach-cv {
  width: 100% !important;
  height: 150px !important;

  label {
    background-color: #ebebeb !important;
    cursor: pointer;
    border: 2px dashed #9b9b9b;
    border-radius: 28px;
  }

  label,
  input {
    width: 100% !important;
    height: 150px !important;
  }
}

#uploadCv {
  background-color: transparent !important;
  border: none;
  display: none;

  &::-webkit-file-upload-button {
    display: none;
  }
}
</style>
