<template>
  <Faq />
</template>
<script>
import Faq from "../components/Common/Faq.vue";

export default {
  name: "FaqPage",
  components: {
    Faq,
  },
};
</script>
