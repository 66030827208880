<template>
  <section class="blog-card-group-wrapper">
    <div
      class="d-none d-md-block ts-bg-radius ts-bg-radius--secondary z-minus-1"
    ></div>
    <div
      class="d-none d-md-block ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
    ></div>

    <img
      class="d-none d-md-block ts-bg-1"
      src="/images/bg-decoration-lines.svg"
      alt="..."
    />
    <img
      class="d-none d-md-block ts-bg-2"
      src="/images/bg-decoration-lines.svg"
      alt="..."
    />
    <img
      class="d-none d-md-block ts-bg-3"
      src="/images/hash-white.svg"
      alt="..."
    />

    <div class="mb-08 d-md-none">
      <select
        class="form-select ts-form-select rounded-pill py-2"
        aria-label="Default select example"
      >
        <option selected>All</option>
        <option value="1">Tips and Tricks</option>
        <option value="2">Content Creation</option>
        <option value="3">Influencer Marketing</option>
      </select>
    </div>
    <div class="d-none d-md-flex flex-wrap gap-07p mb-14">
      <button
        class="ts-btn-tags"
        v-for="tag in tags"
        :key="tag"
        :data-attribute="tag"
        :class="{ 'ts-btn-tags--active': isActive === tag }"
        @click="toggleClass"
      >
        {{ tag }}
      </button>
    </div>

    <div class="blog-card-group mb-11 mb-lg-14">
      <div v-for="card in cards" :key="card.id">
        <NewsCard
          :title="card.title"
          :date="card.date"
          :generatedBy="card.generatedBy"
        />
      </div>
    </div>

    <Pagination />
  </section>
</template>

<script>
import NewsCard from "../Cards/NewsCard.vue";
import Pagination from "./Pagination.vue";

export default {
  name: "BlogCardGroup",
  components: {
    NewsCard,
    Pagination,
  },
  data() {
    return {
      isActive: "All",

      tags: [
        "All",
        "Tips and Tricks",
        "Content Creation",
        "Influencer Marketing",
        "How to",
        "Community",
        "Food Blogging",
        "Branding Strategy",
        "Brand Collaborations",
      ],
      cards: [
        {
          id: 1,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 2,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 3,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 4,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 5,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 6,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 7,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 8,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 9,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
      ],
    };
  },

  methods: {
    toggleClass(event) {
      // Get the clicked element
      const clickedElement = event.target;
      // Get the data attribute value
      const dataAttributeValue = clickedElement.getAttribute("data-attribute");
      // change the active button State
      this.isActive = dataAttributeValue;
    },
  },
};
</script>

<style scoped lang="scss">
.blog-card-group {
  &-wrapper {
    position: relative;
    isolation: isolate;

    .ts-bg-1 {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(75%, -50%);
      z-index: -3;
      width: clamp(2000px, 180vw, 3000px);
    }

    .ts-bg-2 {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-60%, 50%);
      z-index: -2;
      width: clamp(2000px, 180vw, 3000px);
    }

    .ts-bg-3 {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(10%, 30%);
      z-index: -1;
      width: clamp(200px, 40vw, 450px);
    }
  }

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 36px;
  grid-row-gap: clamp(60px, 9vw, 104px);

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ts-bg-radius--secondary {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 1320px !important;
  height: 1320px !important;
  z-index: -4;
  opacity: 83%;
}

.ts-bg-radius--primary-secondary--mix {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-70%, 84%) rotate(45deg);
  width: 819px !important;
  height: 819px !important;
  z-index: -3;
  opacity: 83%;
}

.ts-btn-tags {
  color: #404040;
  background-color: #f6f6f6;
  font-size: 23px;
  border-radius: 50rem;
  border: 1px solid #707070;
  padding: 10px 43px 13px;
  line-height: 100%;
  transition: all 300ms ease-in-out;

  &--active {
    background-color: #00a4b6;
    border-color: #00a4b6;
    color: white;
  }
}
</style>
