<template>
  <nav
    class="navbar navbar-expand-lg fixed-top"
    :class="[
      { 'navbar--open': this.navbarOpen },
      { 'navbar--open--scroll': isScrolled },
      NavBarCustomClasses,
    ]"
  >
    <div class="ts-container-fluid container-fluid">
      <router-link
        class="navbar-brand d-flex fw-bold ts-text-gray-5"
        to="/"
        @click="closeNavbar"
      >
        <div class="d-none d-sm-block">
          <img width="107" src="/images/logo_1.png" alt="..." />
        </div>
        <div class="d-sm-none">
          <img width="36" src="/images/logo.svg" alt="..." />
        </div>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNavbar"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown mb-3 mb-lg-0">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Brands
            </a>
            <ul class="dropdown-menu" :class="{ show: isDropDwonOpen }">
              <li>
                <router-link
                  class="dropdown-item nav-link--active"
                  to="/brands"
                  @click="closeNavbar"
                  >Home
                </router-link>
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="closeNavbar"
                  >Pricing
                </a>
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/contact"
                  @click="closeNavbar"
                  >Contact
                </router-link>
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="closeNavbar"
                  >Log In
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="/brands-signup"
                  @click="closeNavbar"
                  >Sign Up</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown mb-3 mb-lg-0">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Creators
            </a>
            <ul class="dropdown-menu" :class="{ show: isDropDwonOpen }">
              <li>
                <router-link
                  class="dropdown-item"
                  to="/creators"
                  @click="closeNavbar"
                  >Home
                </router-link>
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/contact"
                  @click="closeNavbar"
                  >Contact
                </router-link>
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="closeNavbar"
                  >Log In
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="/creator-signup"
                  @click="closeNavbar"
                  >Sign Up</a
                >
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" to="/blog" @click="closeNavbar"
              >Blog</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/team" @click="closeNavbar"
              >Team</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/career" @click="closeNavbar"
              >Careers</router-link
            >
          </li>

          <li class="nav-item d-flex align-items-center">
            <div class="ts-language-dropdown dropdown">
              <a
                class="ts-language-btn dropdown-toggle dropdown-toggle--white ts-btn ts-btn-primary rounded-2"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                EN
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">FR</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TsNavbar",
  props: {
    NavBarCustomClasses: String,
  },
  data: function () {
    return {
      navbarOpen: false,
      isScrolled: false,
      isDropDwonOpen: false,
    };
  },
  methods: {
    isActive(route) {
      console.log(this.$route.path === route);
      return this.$route.path === route;
    },

    toggleNavbar() {
      // Call your custom method here before toggling the Navbar
      this.handleNavbarToggle();
      // Toggle the Navbar
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    handleNavbarToggle() {
      const navbar = document.querySelector(".navbar");
      const navbarToggler = document.querySelector(".navbar-toggler");
      if (navbarToggler && navbarToggler.classList.contains("collapsed")) {
        this.navbarOpen = false;
        document.body.classList.remove("body-overflow-hidden");
        navbar.classList.remove("navbar--open");
      } else {
        this.navbarOpen = true;
        navbar.classList.add("navbar--open");
        document.body.classList.add("body-overflow-hidden");
      }
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },

    // Run A Function if screen resize or less then
    handleScreenResize() {
      const screenWidth = window.innerWidth;
      // Define the maximum screen width below which you want to run the function
      const maxScreenWidth = 991; // For example, 991px
      if (screenWidth < maxScreenWidth) {
        this.isDropDwonOpen = true;
      } else {
        this.isDropDwonOpen = false;
      }
    },

    // Navbar closed after clicking on navlink
    closeNavbar() {
      const navbar = document.querySelector(".navbar");
      const navbarToggler = document.querySelector(".navbar-toggler");
      const navbarCollapse = document.querySelector(".navbar-collapse");

      this.isNavbarOpen = !this.isNavbarOpen;
      document.body.classList.remove("body-overflow-hidden");
      navbar.classList.remove("navbar--open");
      // navbarToggler.classList.remove("collapsed")
      navbarToggler.setAttribute("aria-expanded", "false");
      navbarCollapse.classList.remove("show");
      this.handleScreenResize();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // Call the function on component mount to handle initial screen size
    window.addEventListener("resize", this.handleScreenResize);
    // Call the function on component mount to handle initial screen size
    this.handleScreenResize();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleScreenResize);
  },
};
</script>

<style lang="scss">
.navbar {
  background-color: rgba(255, 255, 255, 0) !important;

  &--open {
    background-color: white !important;
  }

  &--open--scroll {
    background-color: white !important;
  }
  .ts-language-btn {
    background-color: #757575 !important;
    border-color: #757575 !important;
    line-height: 100%;
    padding: 4px 0.5625rem 3px;
  }
}
.ts-language-dropdown {
  width: 67px !important;
  min-width: 67px !important;
  ul,
  li {
    padding: 0;
  }
  .dropdown-menu {
    min-width: 67px !important;
    width: 67px !important;
  }
  .dropdown-item {
    font-size: 22px;
    padding-block: 4.75px !important;
    line-height: 100%;
  }
}
</style>
