<template>
  <div class="ts-news-card">
    <img
      class="w-100 mb-05 ts-rounded-06"
      src="/images/image-placeholder.png"
      alt="..."
    />
    <p class="d-none d-lg-block ts-text-primary-1 ts-fs-7 fw-light mb-14p">
      {{ date }}
    </p>
    <h5 class="ts-fs-5 ts-text-gray-3 fw-regular mb-04 mb-lg-05">
      {{ title }}
    </h5>
    <div class="d-flex d-lg-none justify-content-between mb-03">
      <p class="ts-text-gray-7 mb-0">By: {{ generatedBy }}</p>
      <p class="ts-text-gray-7 mb-0">{{ date }}</p>
    </div>
    <a
      class="btn d-flex align-items-center border-0 p-0 gap-2"
      href="/blog-post"
    >
      <span class="ts-text-gray-7"> Read more </span>
      <span class="ts-icon">
        <Icon name="arrow-down" />
      </span>
    </a>
  </div>
</template>

<script>
import Icon from "../../assets/icons/index.vue";
export default {
  components: {
    Icon,
  },

  props: {
    title: String,
    date: String,
    generatedBy: String,
  },
};
</script>

<style lang="scss">
.ts-news-card {
  .btn {
    color: #707070 !important;
    font-size: 1.1875rem;

    @media (max-width: 991px) {
      span {
        color: #00a4b6 !important;
      }
      .ts-icon {
        transform: rotate(-90deg);

        svg {
          fill: #00a4b6 !important;
          stroke: #00a4b6 !important;

          path {
            stroke: #00a4b6 !important;
          }
        }
      }
    }
  }
}
</style>
