<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center gap-sm-3">
      <li class="page-item"><a class="page-link active" href="#">1</a></li>
      <li class="page-item"><a class="page-link" href="#">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li>
      <li class="page-item"><a class="page-link" href="#">4</a></li>
      <li class="page-item"><a class="page-link" href="#">5</a></li>
      <li class="page-item"><a class="page-link" href="#">6</a></li>
      <li class="page-item"><a class="page-link" href="#">7</a></li>
      <li class="page-item">
        <a class="page-link" href="#">
          <Icon name="chevron-right" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Icon from "../../assets/icons/index.vue";
export default {
  name: "BlogPagination",
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
.pagination {
  .page-link {
    border: 0;
    color: #4d4d4d;
    font-weight: bold;
    line-height: 100%;
    padding-block: 0;
    font-size: clamp(19px, 3.3vw, 22px);
  }
}

.page-item-back {
  svg {
    transform: rotate(180deg);
  }
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: #00a4b6;
  background-color: transparent;
  border-color: none;
}
</style>
