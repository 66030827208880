<template>
  <div class="h-100 d-flex flex-column">
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center my-0"
    >
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        Let's get you started
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-0">
        Please fill out your details
      </p>
    </header>
    <form class="my-auto">
      <p class="ts-sentyou-otp ts-fs-7 fw-light mb-03 text-center mb-04">
        We will send you an OTP to your email. Please enter below.
      </p>

      <div class="ts-otp-input">
        <v-otp-input
          ref="otpInput"
          v-model:value="bindModal"
          input-classes="otp-input"
          separator=""
          :num-inputs="4"
          :should-auto-focus="true"
          input-type="letter-numeric"
          :conditionalClass="['one', 'two', 'three', 'four']"
          :placeholder="[' ', ' ', ' ', ' ']"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "CreatorForm3",
  components: {},

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.ts-sentyou-otp {
  max-width: 317px;
  margin-inline: auto;
}
.ts-otp-input {
  display: flex;
  justify-content: center;
}
.otp-input {
  width: 58px !important;
  height: 50px !important;
  padding: 5px;
  margin: 0 6px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  text-align: center;
  background-color: #ebebeb;
  &::placeholder {
    content: "";
  }
  &:focus {
    border: 1px solid #c0c0c0;
    outline: none;
  }
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #ebebeb;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
