<template>
  <TsHero />
  <div class="ts-container-fluid container-fluid">
    <BrandsLogos>
      <h1 class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-06 mb-lg-10">
        Partners
      </h1>
    </BrandsLogos>
    <HarnessPower />
    <HowItWorks />
  </div>
  <BrandLoves v-bind:haveGradient="false">
    <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
      Over <span class="ts-text-primary-1"> 2,500</span>
      <br class="d-sm-none" />
      Satisfied brands
    </h1>
  </BrandLoves>
  <div class="ts-container-fluid container-fluid">
    <LastestNews>
      <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
        Latest in influencer marketing
      </h1>
    </LastestNews>
  </div>
</template>

<script>
import TsHero from "../components/Brands/TsHero.vue";
import BrandsLogos from "../components/Common/BrandsLogos.vue";
import HarnessPower from "../components/Brands/HarnessPower.vue";
import HowItWorks from "../components/Brands/HowItWorks.vue";
import BrandLoves from "../components/BrandLoves.vue";
import LastestNews from "../components/LastestNews.vue";

export default {
  name: "BrandsPage",
  components: {
    TsHero,
    BrandsLogos,
    HarnessPower,
    HowItWorks,
    BrandLoves,
    LastestNews,
  },
};
</script>
