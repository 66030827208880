<template>
  <section class="ts-container-fluid container-fluid">
    <div class="ts-blog-article ts-text-gray-2">
      <div class="d-none d-md-block">
        <h1 class="ts-blog-article__title fw-bold mb-08 mb-lg-11">
          The Ultimate Guide on How to Find the Perfect Influencer for Your
          Brand
        </h1>
        <p class="ts-blog-article__subtitle fw-regular mb-04 mb-lg-05">
          BY CHAT GPT | JUN 13, 2023 | HOW TO, INFLUENCER MARKETING
        </p>
      </div>
      <img
        class="ts-main-img w-100 mb-06 mb-lg-14"
        src="/images/blog/emojis3.jpg"
        alt="..."
      />
      <div class="d-md-none mb-08">
        <h1 class="ts-blog-article__title fw-bold mb-02">
          The Ultimate Guide on How to Find the Perfect Influencer for Your
          Brand
        </h1>
        <p class="ts-blog-article__subtitle fw-regular mb-0">
          BY CHAT GPT | JUN 13, 2023 | HOW TO, INFLUENCER MARKETING
        </p>
      </div>
      <p class="ts-blog-desc fw-regular mb-07 mb-lg-10">
        In today's digital age, influencer marketing has become a powerful tool
        for brands to reach their target audience and drive engagement.
        Collaborating with the right influencer can skyrocket brand awareness,
        boost credibility, and generate meaningful results. However, finding the
        perfect influencer for your brand requires careful consideration and
        strategic planning. In this blog post, we will guide you through the
        process of identifying and partnering with influencers who align with
        your brand values, target audience, and campaign objectives.
      </p>
      <h2 class="ts-fs-2-ii fw-bold mb-07 mb-lg-10">1. Define Your Goals</h2>
      <p class="ts-blog-desc fw-regular mb-07 mb-lg-10">
        In today's digital age, influencer marketing Before diving into the
        search for an influencer, clearly define your campaign goals. Determine
        what you aim to achieve through influencer marketing, whether it's
        increasing brand visibility, driving website traffic, or boosting
        product sales. By setting specific goals, you can narrow down your
        search and find influencers whose strengths align with your objectives.
      </p>
      <h2 class="ts-fs-2-ii fw-bold mb-07 mb-lg-10">
        2. Understand Your Target Audience
      </h2>
      <p class="ts-blog-desc fw-regular mb-07 mb-lg-10">
        In today's digital age, influencer marketing To find an influencer who
        resonates with your target audience, it's crucial to have a deep
        understanding of who they are. Develop detailed buyer personas,
        considering demographic factors, interests, and online behavior. Use
        social media analytics and market research to gain insights into your
        audience's preferences and the platforms they frequent. This knowledge
        will help you identify influencers with followers who closely match your
        target audience.
      </p>
      <h2 class="ts-fs-2-ii fw-bold mb-07 mb-lg-10">
        3. Research and Vet Influencers
      </h2>
      <p class="ts-blog-desc fw-regular mb-07 mb-lg-10">
        In today's digital age, influencer marketing Once you have a clear
        picture of your target audience, it's time to research potential
        influencers. Look for influencers who create content relevant to your
        industry, have a genuine engagement with their followers, and reflect
        the values and aesthetics of your brand. Pay attention to their content
        quality, style, and tone to ensure it aligns with your brand's image.
      </p>
      <h2 class="ts-fs-2-ii fw-bold mb-07 mb-lg-10">Conclusion</h2>
      <p class="ts-blog-desc fw-regular mb-0">
        Finding the perfect influencer for your brand requires careful research,
        analysis, and alignment of values. By defining your goals, understanding
        your target audience, and evaluating influencers based on engagement,
        authenticity, and brand alignment, you can establish successful
        partnerships that drive meaningful results. Remember, influencer
        marketing is a powerful tool when executed strategically, and with the
        right influencer by your side, your brand can unlock new levels of
        success.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    date: String,
    generatedBy: String,
  },
};
</script>

<style lang="scss">
.ts-blog-article {
  max-width: 1100px;
  margin-inline: auto;
  margin-bottom: clamp(110px, 12vw, 11.25rem);

  &__title {
    font-size: clamp(1.5625rem, 5.5vw, 3.4375rem);
  }

  .ts-main-img {
    border-radius: clamp(18px, 3.5vw, 28px);
    object-fit: cover;
    min-height: 194px;
  }

  .ts-blog-desc {
    font-size: clamp(0.9375rem, 2.6vw, 1.75rem);
  }

  &__subtitle {
    font-size: clamp(11px, 2.5vw, 18px);
  }
}

.ts-news-card {
  .btn {
    font-size: 1.1875rem;

    @media (max-width: 991px) {
      span {
        color: #00a4b6 !important;
      }

      .ts-icon {
        transform: rotate(-90deg);

        svg {
          fill: #00a4b6 !important;
          stroke: #00a4b6 !important;

          path {
            stroke: #00a4b6 !important;
          }
        }
      }
    }
  }
}
</style>
