<template>
  <TsHero />
  <div class="ts-container-fluid container-fluid">
    <BrandsLogos>
      <h1 class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-06 mb-lg-10">
        Brands
      </h1>
    </BrandsLogos>
    <FuelYourBussiness />
  </div>
  <BrandLoves v-bind:haveGradient="true">
    <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
      Brands love us
    </h1>
  </BrandLoves>
  <div class="ts-container-fluid container-fluid">
    <UnlockBenifits />
  </div>
  <div class="ts-container-fluid container-fluid">
    <CreatorsReviews>
      <h1 class="ts-fs-1 text-center ts-text-gray-5 fw-bold mb-07 mb-lg-13">
        Creators love us
      </h1>
    </CreatorsReviews>
    <LastestNews>
      <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
        Latest in influencer marketing
      </h1>
    </LastestNews>
  </div>
</template>

<script>
// import TsNavbar from "../components/layout/TsNavbar.vue";
import TsHero from "../components/Home/TsHero.vue";
import BrandsLogos from "../components/Common/BrandsLogos.vue";
import FuelYourBussiness from "../components/FuelYourBussiness.vue";
import UnlockBenifits from "../components/UnlockBenifits.vue";
import BrandLoves from "../components/BrandLoves.vue";
import CreatorsReviews from "../components/CreatorsReviews.vue";
import LastestNews from "../components/LastestNews.vue";
// import TsFooter from "../components/layout/TsFooter.vue";

export default {
  name: "App",
  components: {
    // TsNavbar,
    TsHero,
    BrandsLogos,
    FuelYourBussiness,
    UnlockBenifits,
    BrandLoves,
    CreatorsReviews,
    LastestNews,
    // TsFooter,
  },
};
</script>
