<template>
  <layout />
</template>
<script>
import layout from "./components/layout/index.vue";
export default {
  name: "App",
  components: {
    layout,
  },
};
</script>

<style lang="scss">
@import "./styles/index.scss";
</style>
