<template>
  <section class="ts-form-hero ts-brand-form-hero">
    <div class="ts-bg-gradient d-md-none">
      <div class="ts-gradient-bg-1 ts-bg-radius ts-bg-radius--secondary"></div>
      <div
        class="ts-gradient-bg-2 ts-bg-radius ts-bg-radius--primary-secondary--mix"
      ></div>
    </div>
    <div class="ts-container-fluid container-fluid">
      <div class="ts-brand-form-hero__content">
        <div v-show="elementVisible">
          <div class="ts-image ts-image__1 d-none d-md-block">
            <img src="/images/tiktok.png" alt="..." />
          </div>
          <div class="ts-image ts-image__2">
            <div class="ts-rotate">
              <img src="/images/Home/heart.png" alt="..." />
            </div>
          </div>
          <div class="ts-image ts-image__3 d-none d-md-block">
            <img src="/images/Home/camera.png" alt="..." />
          </div>
          <div class="ts-image ts-image__4 d-none d-md-block">
            <img src="/images/mic.png" alt="..." />
          </div>
          <div class="ts-image ts-image__5 d-none d-md-block">
            <div class="ts-rotate">
              <img src="/images/Home/heart.png" alt="..." />
            </div>
          </div>
        </div>

        <div
          class="ts-brand-form-hero__content--main row row-cols-lg-2 align-items-center gap-5 gap-lg-0"
        >
          <div class="order-1 order-lg-0 ts-text">
            <header class="ts-brand-form-hero__header ts-text-gray-2b">
              <img
                height="43"
                class="mb-03"
                src="/icons/quote-close-svgrepo-com.svg"
                alt="..."
              />

              <h1 class="ts-text-gray-2b ts-fs-2-b fw-bold mb-04 mb-lg-07">
                No other platform compares.
              </h1>
              <p class="ts-text-gray-2b ts-fs-5 fw-light mb-05 mb-lg-07">
                Their attention to detail, reliable communication, and ability
                to match us with influential creators have made all the
                difference!
              </p>

              <div class="d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-1a fw-medium mb-0">
                    Jessica Lewis
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-1a mb-0">
                    Marketing Director - Brand
                  </p>
                </div>
              </div>
            </header>
          </div>
          <div class="order-0 order-lg-1 ts-form">
            <div class="ts-contact">
              <div class="ts-contact__content">
                <div class="ts-contact-form-container ts-shadow-1">
                  <div class="mb-3">
                    <swiper
                      :slides-per-view="1"
                      @swiper="onSwiper"
                      :spaceBetween="16"
                      :pagination="{
                        clickable: true,
                        el: '.ts-brands-form-swiper',
                      }"
                      :modules="modules"
                      class="ts-brands-swiper mySwiper"
                      :class="isLastSlide ? 'd-none' : ''"
                      ref="tsbrandsSwiper"
                      @slideChange="handleSlideChange"
                    >
                      <swiper-slide> <Form1 /> </swiper-slide>
                      <swiper-slide> <Form2 /> </swiper-slide>
                      <swiper-slide class="h-auto"> <Form3 /> </swiper-slide>
                    </swiper>

                    <div :class="isLastSlide ? '' : 'd-none'">
                      <Congratulation />
                    </div>
                    <div
                      class="ts-brands-form-swiper ts-swiper-pagination-2 d-flex justify-content-center"
                      :class="isLastSlide ? 'invisible' : ''"
                    ></div>
                  </div>
                  <button
                    class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill"
                    @click="handleSlideNext"
                    :class="
                      (btnText === 'Continue'
                        ? 'ts-btn-primary'
                        : 'ts-btn-secondary',
                      isLastSlide ? 'd-none' : '')
                    "
                  >
                    {{ btnText }}
                  </button>
                  <button
                    class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-secondary w-100 rounded-pill"
                    @click="handleCongratulations"
                    :class="isLastSlide ? '' : 'd-none'"
                  >
                    Done
                  </button>
                  <h3
                    class="ts-fs-7-ii text-center ts-already-signup mb-0 fw-normal"
                    :class="isLastSlide ? 'invisible' : ''"
                  >
                    Already have an account?
                    <a
                      class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                      href="#"
                    >
                      Sign in</a
                    >
                  </h3>
                </div>

                <h3
                  class="ts-fs-7-ii text-center ts-already-signup-2 fw-normal"
                >
                  Are you a creator?

                  <router-link
                    class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                    to="/creator-signup"
                  >
                    Sign up here
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <!-- v-show="elementVisible" -->
        <img
          v-show="elementVisible"
          class="ts-brands-bg-2"
          src="/images/logo_bg.png"
          alt="..."
        />
      </div>
    </div>
  </section>
</template>
<script>
import Form1 from "./brands/Form1.vue";
import Form2 from "./brands/Form2.vue";
import Form3 from "./brands/Form3.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Navigation } from "swiper/modules";

import Congratulation from "./common/Congratulation.vue";

export default {
  name: "FormHero",
  components: {
    Swiper,
    SwiperSlide,
    Form1,
    Form2,
    Form3,
    Congratulation,
  },

  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },

  data() {
    return {
      btnText: "Continue",
      isLastSlide: false,
      isLastSlideActive: false,
      swiper: null,
      elementVisible: false,
    };
  },
  created() {
    setTimeout(() => (this.elementVisible = true), 500);
  },

  methods: {
    onSubmit() {
      console.log("Working");
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleSlideChange() {
      this.isLastSlideActive = false;
      this.btnText = "Continue";
      if (this.swiper.isEnd) {
        this.btnText = "Done";
      }
    },
    handleSlideNext() {
      // this.isLastSlide = false;
      this.swiper.slideNext();
      this.btnText = "Continue";

      if (this.isLastSlideActive) {
        this.isLastSlide = true;
      }

      if (this.swiper.isEnd) {
        this.btnText = "Done";
        this.isLastSlideActive = true;
      }
    },

    handleCongratulations() {
      this.swiper.slideTo(0);
      this.isLastSlide = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.ts-brands-bg-2 {
  position: absolute;
  left: 100%;
  top: 25%;
  width: 2160px;
  transform: translate(-35%, -50%) !important;
  z-index: -2;

  @media (min-width: 992px) {
    left: 75%;
    top: 48%;
    transform: translate(-50%, -50%) !important;
  }
}

.ts-bg-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
.ts-gradient-bg-1 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -10%);
  width: clamp(1200px, 140vw, 1700px);
  z-index: -3;
}
.ts-gradient-bg-2 {
  position: absolute;
  top: 22%;
  right: 0;
  transform: translate(94%, 0%);
  width: clamp(1200px, 140vw, 1700px);
  z-index: -3;
}
.ts-brand-form-hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;

  width: 100%;
  overflow: hidden;
  &__content {
    position: relative;
    isolation: isolate;
    max-width: 1224px;
    margin: auto;

    &--main {
      position: relative;
      isolation: isolate;
      z-index: 10;
    }
  }

  .ts-text {
    position: relative;
    z-index: 1;
  }
  .ts-form {
    position: relative;
    z-index: 2;
  }
}
.ts-form-hero {
  padding-block: 6.5625rem 3.5rem;
  position: relative;
  isolation: isolate;

  @media (min-width: 992px) {
    padding-block: 12rem 6rem;
  }
}

@media (max-width: 475px) {
  .ts-brand-form-hero__header {
    max-width: 338px;
    margin: auto;
  }
}
.ts-contact {
  margin: 0 !important;
  position: relative;
  isolation: isolate;
}

.ts-image {
  position: absolute;
  img {
    width: clamp(80px, 11vw, 120px) !important;
  }

  &__1 {
    img {
      animation: floating 2.5s ease-in-out infinite;
    }
  }

  &__2 {
    img {
      animation: floating 3s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(30deg);
    }
  }

  &__3 {
    img {
      animation: floating 3.5s ease-in-out infinite;
    }
  }
  &__4 {
    img {
      animation: floating 4s ease-in-out infinite;
    }
  }
  &__5 {
    img {
      animation: floating 4s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(-30deg);
    }
  }

  &__1,
  &__3,
  &__5 {
    left: 40%;
    transform: translateX(-100%);
  }

  &__2,
  &__4 {
    right: 0;
    transform: translateX(100%);
  }

  &__1,
  &__2 {
    transform: translateY(-100%);
  }

  &__1 {
    left: 41%;
    top: 32%;
  }
  &__2 {
    top: 109%;
    right: -5%;
  }

  &__3 {
    top: 15%;
    left: 0;
    transform: translate(-100%, -100%);
  }

  &__4 {
    right: 65% !important;
    top: 100%;
    transform: translate(100%, -100%);
    img {
      width: clamp(80px, 15vw, 170px) !important;
    }
  }

  &__5 {
    bottom: 0;
    left: 5%;
    transform: translate(-120%, 40%);
  }

  @media (max-width: 1400px) {
    &__3 {
      transform: translate(0, -100%) !important;
    }
    &__5 {
      left: 0;
      transform: translate(0, 40%) !important;
    }
  }
  @media (max-width: 991px) {
    &__1,
    &__3,
    &__4,
    &__5 {
      display: none !important;
    }
    &__2 {
      top: 75%;
      right: 0;
    }
  }
}

@keyframes floating {
  0% {
    transform: rotate(-5deg) translatey(0px);
  }

  50% {
    transform: rotate(-3deg) translatey(7px);
  }

  100% {
    transform: rotate(-5deg) translatey(0px);
  }
}
</style>
