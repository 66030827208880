<template>
  <section class="ts-creator-love-us">
    <slot></slot>
    <swiper
      :slidesPerView="1"
      :spaceBetween="16"
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      :breakpoints="{
        '700': {
          slidesPerView: 2,
          spaceBetween: 28,
        },
        '1100': {
          slidesPerView: 3,
          spaceBetween: 36,
        },
      }"
      class="ts-custom-swiper tsCreatorLoveSwiper"
    >
      <swiper-slide>
        <div
          class="ts-creator-card ts-bg-primary-3 d-flex flex-column gap-4 justify-content-between"
        >
          <div>
            <img
              class="ts-creator-card__qoute-img mb-06 mb-lg-08"
              src="/images/double-qoute.svg"
              alt="..."
            />
            <h5 class="ts-fs-6 ts-text-gray-2 fw-light ts-fs-7 mb-03">
              Our go-to platform for influencer collaborations. Their dedicated
              and responsive team ensures a seamless experience, exceeding our
              expectations!
            </h5>
          </div>
          <div class="text-center">
            <img
              class="ts-creator-card__user-img rounded-circle mb-02"
              src="/images/happy-girl-posing.png"
              alt="..."
            />
            <p class="ts-text-gray-6 mb-02 mb-lg-03">Alison Wilson</p>

            <div
              class="ts-social-links d-flex gap-3 gap-sm-4 justify-content-center"
            >
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-instagram"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitch"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="ts-creator-card ts-bg-primary-3 d-flex flex-column gap-4 justify-content-between"
        >
          <div>
            <img
              class="ts-creator-card__qoute-img mb-06 mb-lg-08"
              src="/images/double-qoute.svg"
              alt="..."
            />
            <h5 class="ts-fs-6 ts-text-gray-2 fw-light ts-fs-7 mb-03">
              Our go-to platform for influencer collaborations. Their dedicated
              and responsive team ensures a seamless experience, exceeding our
              expectations!
            </h5>
          </div>
          <div class="text-center">
            <img
              class="ts-creator-card__user-img rounded-circle mb-02"
              src="/images/happy-girl-posing.png"
              alt="..."
            />
            <p class="ts-text-gray-6 mb-03 mb-lg-03">Alison Wilson</p>

            <div
              class="ts-social-links d-flex gap-3 gap-sm-4 justify-content-center"
            >
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-instagram"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitch"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="ts-creator-card ts-bg-primary-3 d-flex flex-column gap-4 justify-content-between"
        >
          <div>
            <img
              class="ts-creator-card__qoute-img mb-06 mb-lg-08"
              src="/images/double-qoute.svg"
              alt="..."
            />
            <h5 class="ts-fs-6 ts-text-gray-2 fw-light ts-fs-7 mb-03">
              Our go-to platform for influencer collaborations. Their dedicated
              and responsive team ensures a seamless experience, exceeding our
              expectations!
            </h5>
          </div>
          <div class="text-center">
            <img
              class="ts-creator-card__user-img rounded-circle mb-02"
              src="/images/happy-girl-posing.png"
              alt="..."
            />
            <p class="ts-text-gray-6 mb-03 mb-lg-03">Alison Wilson</p>

            <div
              class="ts-social-links d-flex gap-3 gap-sm-4 justify-content-center"
            >
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-instagram"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitch"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="ts-creator-card ts-bg-primary-3 d-flex flex-column gap-4 justify-content-between"
        >
          <div>
            <img
              class="ts-creator-card__qoute-img mb-06 mb-lg-08"
              src="/images/double-qoute.svg"
              alt="..."
            />
            <h5 class="ts-fs-6 ts-text-gray-2 fw-light ts-fs-7 mb-03">
              Our go-to platform for influencer collaborations. Their dedicated
              and responsive team ensures a seamless experience, exceeding our
              expectations!
            </h5>
          </div>
          <div class="text-center">
            <img
              class="ts-creator-card__user-img rounded-circle mb-02"
              src="/images/happy-girl-posing.png"
              alt="..."
            />
            <p class="ts-text-gray-6 mb-03 mb-lg-03">Alison Wilson</p>

            <div
              class="ts-social-links d-flex gap-3 gap-sm-4 justify-content-center"
            >
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-instagram"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitch"></i>
              </a>
              <a class="ts-text-gray-4" href="#">
                <i class="bi bi-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },

  name: "LastestNews",
};
</script>

<style scoped lang="scss">
.ts-social-links {
  font-size: clamp(21px, 4.4vw, 27px);
}
</style>
