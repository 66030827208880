<template>
  <div>
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center"
    >
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        Let's get you started
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-07">
        Please fill out your details
      </p>
    </header>
    <form>
      <div class="row row-cols-2 px-2">
        <div class="mb-2 px-1">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="firstName"
            placeholder="First Name"
          />
        </div>
        <div class="mb-2 px-1">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="lastName"
            placeholder="Last Name"
          />
        </div>
      </div>
      <div class="mb-2">
        <select
          class="form-select form-select-lg rounded-pill ts-phone"
          aria-label="Gender"
        >
          <option selected>Gender</option>
          <option value="1">Male</option>
          <option value="2">Female</option>
        </select>
      </div>
      <div class="mb-08">
        <select
          class="form-select form-select-lg rounded-pill ts-phone"
          aria-label="Current Location"
        >
          <option selected>Current Location</option>
          <option value="1">Male</option>
          <option value="2">Female</option>
        </select>
      </div>
      <p class="ts-fs-7 px-3">Birthday</p>
      <div class="row row-cols-3 mb-2 px-2">
        <div class="px-1">
          <select
            class="form-select form-select-lg rounded-3 ts-phone"
            aria-label="Current Location"
          >
            <option selected>Year</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </select>
        </div>
        <div class="px-1">
          <select
            class="form-select form-select-lg rounded-3 ts-phone"
            aria-label="Current Location"
          >
            <option selected>Month</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </select>
        </div>
        <div class="px-1">
          <select
            class="form-select form-select-lg rounded-3 ts-phone"
            aria-label="Current Location"
          >
            <option selected>Day</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </select>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "CreatorForm1",

  data() {
    return {};
  },
};
</script>
