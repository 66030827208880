<template>
  <div class="ts-blog-page-wrapper">

    <BlogArticle />
    <div class="ts-container-fluid container-fluid">
      <LastestNews>
        <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
          Related Posts
        </h1>
      </LastestNews>
    </div>
  </div>
</template>

<script>
import LastestNews from "../components/LastestNews.vue";
import BlogArticle from "../components/Blog/BlogArticle.vue";

export default {
  name: "BlogPost",
  components: {
    BlogArticle,
    LastestNews,
  },
};
</script>

<style scoped lang="scss">
.ts-blog-page-wrapper {
  padding-top: clamp(160px, 15vw, 15rem);
  position: relative;
  isolation: isolate;

  @media (max-width:767px) {

    padding-top: clamp(160px, 10vw, 15rem);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: clamp(300px, 75vw, 750px);
    background-color: #F5F5F5;
    z-index: -1;

    @media (max-width:767px) {
      height: clamp(240px, 55vw, 350px);
    }
  }
}
</style>