<template>
  <TsNavbar :NavBarCustomClasses="NavBarCustomClasses" />
  <main class="ts-wrapper">
    <RouterView
      @data-navBarCustomClasses="receiveDataFromChild"
      @data-hideFooter="hideFooterFromChild"
    />
  </main>

  <TsFooter v-if="!hideFooter" />
</template>

<script>
import TsNavbar from "./TsNavbar.vue";
import TsFooter from "./TsFooter.vue";
export default {
  name: "App",
  components: {
    TsNavbar,
    TsFooter,
  },

  data() {
    return {
      NavBarCustomClasses: "",
      hideFooter: "",
    };
  },
  methods: {
    receiveDataFromChild(data) {
      this.NavBarCustomClasses = data;
    },
    hideFooterFromChild(data) {
      this.hideFooter = data;
    },
  },
};
</script>
