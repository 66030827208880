<template>
  <TsHero />
  <div class="ts-container-fluid container-fluid">
    <BrandsLogos>
      <h1 class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-06 mb-lg-10">
        Brands
      </h1>
    </BrandsLogos>
    <HowItWorks />
  </div>
  <div class="ts-container-fluid container-fluid">
    <CreatorsReviews>
      <h1 class="ts-fs-1 text-center ts-text-gray-5 fw-bold mb-07 mb-lg-13">
        Over <span class="ts-text-primary-1"> 35,000</span> Happy Creators
      </h1>
    </CreatorsReviews>
    <LastestNews>
      <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
        Latest in influencer marketing
      </h1>
    </LastestNews>
  </div>
</template>

<script>
import TsHero from "../components/Creators/TsHero.vue";
import BrandsLogos from "../components/Common/BrandsLogos.vue";
import HowItWorks from "../components/Creators/HowItWorks.vue";
import CreatorsReviews from "../components/CreatorsReviews.vue";
import LastestNews from "../components/LastestNews.vue";

export default {
  name: "CreatorsPage",
  components: {
    TsHero,
    BrandsLogos,
    HowItWorks,
    CreatorsReviews,
    LastestNews,
  },
};
</script>
