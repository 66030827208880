<template>
  <div>
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center"
    >
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        Let's get you started
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-11">
        Please fill out your details
      </p>
    </header>
    <form>
      <div class="mb-2">
        <select
          class="form-select form-select-lg rounded-pill ts-phone"
          aria-label="Nationality"
        >
          <option selected>Nationality</option>
          <option value="1">Male</option>
          <option value="2">Female</option>
        </select>
      </div>
      <div class="ts-form-control-group d-flex mb-2">
        <div class="">
          <select
            class="form-select form-select-lg rounded-pill ts-phone"
            aria-label="Default select example"
          >
            <option selected>+971</option>
            <option value="1">+972</option>
            <option value="2">+973</option>
            <option value="3">+974</option>
          </select>
        </div>
        <div class="flex-fill">
          <input
            type="tel"
            class="form-control form-control-lg rounded-pill"
            id="tsPhone"
            aria-describedby="PhoneHelp"
            placeholder="Phone"
          />
        </div>
      </div>

      <div class="px-3 mb-08 mt-2">
        <div class="form-check d-flex gap-2">
          <input
            class="ts-form-check-input form-check-input rounded-circle mb-1"
            type="checkbox"
            name="sameWhatsNumber"
            id="sameWhatsNumber"
            value="option1"
          />
          <label class="form-check-label" for="sameWhatsNumber">
            Use same number for Whastapp
          </label>
        </div>
      </div>
      <div class="ts-form-control-group d-flex mb-2">
        <div class="">
          <select
            class="form-select form-select-lg rounded-pill ts-phone"
            aria-label="Default select example"
          >
            <option selected>+971</option>
            <option value="1">+972</option>
            <option value="2">+973</option>
            <option value="3">+974</option>
          </select>
        </div>
        <div class="flex-fill">
          <input
            type="tel"
            class="form-control form-control-lg rounded-pill"
            id="tsWhastapp Number"
            aria-describedby="Whastapp NumberHelp"
            placeholder="Whastapp Number"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "CreatorForm2",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
