<template>
  <div class="ts-congratulation d-flex align-items-center h-100">
    <div class="text-center ts-congratulation__content">
      <div class="me-4">
        <img
          class="mb-5"
          height="134"
          src="/images/congratulation.svg"
          alt="..."
        />
      </div>
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        Congratulations!
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-3">
        Your membership request is under review and you will be notified upon
        approval of your application.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "congratulationSection",
  //   data() {
  //     return {};
  //   },
};
</script>
<style lang="scss" scoped>
.ts-congratulation {
  min-height: 413px;
}
.ts-congratulation__content {
  max-width: 343px;
  margin: auto;
}
</style>
