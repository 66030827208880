<template>
  <TsHero />
  <div class="ts-container-fluid container-fluid ">
    <div class=" mb-10 mb-lg-12 ">
      <BlogCardGroup />
    </div>
  </div>
</template>

<script>
import TsHero from "../components/Blog/TsHero.vue";
import BlogCardGroup from "../components/Blog/BlogCardGroup.vue";

export default {
  name: "BlogPage",
  components: {
    TsHero,
    BlogCardGroup,
  },
};
</script>
