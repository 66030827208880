<template>
  <section class="ts-latest-news">
    <slot></slot>
    <swiper
      :slidesPerView="1"
      :spaceBetween="16"
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      :breakpoints="{
        '700': {
          slidesPerView: 2,
          spaceBetween: 28,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 36,
        },
      }"
      class="ts-custom-swiper mySwiper"
    >
      <swiper-slide class="mb-4" v-for="card in cards" :key="card.id">
        <NewsCard
          :title="card.title"
          :date="card.date"
          :generatedBy="card.generatedBy"
        />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import NewsCard from "../components/Cards/NewsCard.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
    NewsCard,
  },

  setup() {
    return {
      modules: [Pagination],
    };
  },

  data() {
    return {
      cards: [
        {
          id: 1,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 2,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 3,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 4,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 5,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 6,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 7,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
        {
          id: 8,
          title: "How to Find the Perfect Influencer for Your Brand",
          date: "JUNE 4, 2023",
          generatedBy: "CHAT GPT",
        },
      ],
    };
  },
  name: "LastestNews",
};
</script>
