<template>
  <div class="ts-brand-loves position-relative isolate">
    <div class="ts-container-fluid container-fluid">
      <slot></slot>
    </div>
    <div
      v-if="haveGradient"
      class="ts-bg-radius ts-bg-radius--primary-2 z-minus-1"
    ></div>
    <div class="ts-container-fluid container-fluid overflow-hidden">
      <div>
        <swiper
          :slidesPerView="1"
          :spaceBetween="30"
          :loop="true"
          :pagination="{
            clickable: true,
          }"
          :autoplay="{
            delay: 2000,
            disableOnInteraction: false,
          }"
          :modules="modules"
          :breakpoints="{
            '700': {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            '1024': {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }"
          class="ts-custom-swiper brandLovesSwiper overflow-visible py-2"
        >
          <swiper-slide>
            <div
              class="ts-creator-card ts-shadow-2 d-flex flex-column gap-4 justify-content-between"
            >
              <div>
                <img
                  class="ts-creator-card__img mb-05 mb-lg-08"
                  src="/icons/quote-close-gray.svg"
                  alt="..."
                />
                <h5 class="ts-fs-7 ts-text-gray-7 fw-light mb-03">
                  No other platform compares. Their attention to detail,
                  reliable communication, and ability to match us with
                  influential creators have made all the difference!
                </h5>
              </div>
              <div class="d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-7 fw-medium mb-0">
                    Emily Watson
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-7 mb-0">
                    Marketing Director - Brand
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="ts-creator-card ts-shadow-2 d-flex flex-column gap-4 justify-content-between ts-text-gray-7"
            >
              <div>
                <img
                  class="ts-creator-card__img mb-05 mb-lg-08"
                  src="/icons/quote-close-gray.svg"
                  alt="..."
                />
                <h5 class="ts-fs-7 ts-text-gray-7 fw-light mb-03">
                  No other platform compares. Their attention to detail,
                  reliable communication, and ability to match us with
                  influential creators have made all the difference!
                </h5>
              </div>
              <div class="d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-7 fw-medium mb-0">
                    Emily Watson
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-7 mb-0">
                    Marketing Director - Brand
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="ts-creator-card ts-shadow-2 d-flex flex-column gap-4 justify-content-between ts-text-gray-7"
            >
              <div>
                <img
                  class="ts-creator-card__img mb-05 mb-lg-08"
                  src="/icons/quote-close-gray.svg"
                  alt="..."
                />
                <h5 class="ts-fs-7 ts-text-gray-7 fw-light mb-03">
                  No other platform compares. Their attention to detail,
                  reliable communication, and ability to match us with
                  influential creators have made all the difference!
                </h5>
              </div>
              <div class="d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-7 fw-medium mb-0">
                    Emily Watson
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-7 mb-0">
                    Marketing Director - Brand
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="ts-creator-card ts-shadow-2 d-flex flex-column gap-4 justify-content-between ts-text-gray-7"
            >
              <div>
                <img
                  class="ts-creator-card__img mb-05 mb-lg-08"
                  src="/icons/quote-close-gray.svg"
                  alt="..."
                />
                <h5 class="ts-fs-7 ts-text-gray-7 fw-light mb-03">
                  No other platform compares. Their attention to detail,
                  reliable communication, and ability to match us with
                  influential creators have made all the difference!
                </h5>
              </div>
              <div class="d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-7 fw-medium mb-0">
                    Emily Watson
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-7 mb-0">
                    Marketing Director - Brand
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="ts-creator-card ts-shadow-2 d-flex flex-column gap-4 justify-content-between ts-text-gray-7"
            >
              <div>
                <img
                  class="ts-creator-card__img mb-05 mb-lg-08"
                  src="/icons/quote-close-gray.svg"
                  alt="..."
                />
                <h5 class="ts-fs-7 ts-text-gray-7 fw-light mb-03">
                  No other platform compares. Their attention to detail,
                  reliable communication, and ability to match us with
                  influential creators have made all the difference!
                </h5>
              </div>
              <div class="d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-7 fw-medium mb-0">
                    Emily Watson
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-7 mb-0">
                    Marketing Director - Brand
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="ts-creator-card ts-shadow-2 d-flex flex-column gap-4 justify-content-between ts-text-gray-7"
            >
              <div>
                <img
                  class="ts-creator-card__img mb-05 mb-lg-08"
                  src="/icons/quote-close-gray.svg"
                  alt="..."
                />
                <h5 class="ts-fs-7 ts-text-gray-7 fw-light mb-03">
                  No other platform compares. Their attention to detail,
                  reliable communication, and ability to match us with
                  influential creators have made all the difference!
                </h5>
              </div>
              <div class="d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-7 fw-medium mb-0">
                    Emily Watson
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-7 mb-0">
                    Marketing Director - Brand
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },

  name: "BrandLoves",
  props: {
    haveGradient: Boolean,
  },
};
</script>

<style scoped lang="scss">
.ts-brand-loves {
  position: relative;
}
.ts-creator-card__img {
  height: clamp(30px, 5vw, 50px);
}
.ts-bg-radius {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(10%, -45%);
  width: 1071px !important;
  height: 1071px !important;
  padding-top: 1071px !important;
  opacity: 50%;
  @media (max-width: 767px) {
    width: 434px !important;
    height: 434px !important;
    padding-top: 434px !important;
    transform: translate(30%, -26%);
    opacity: 100%;
  }
}
</style>
