<template>
  <section class="ts-form-hero">
    <div class="ts-bg-gradient">
      <div class="ts-bg-1 ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
      <div class="ts-bg-3 ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
    </div>
    <div
      class="ts-bg-2 ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
    ></div>
    <div class="ts-container-fluid container-fluid">
      <div class="ts-form-hero__content mx-auto">
        <img class="ts-hash" src="/images/hash-white.svg" alt="..." />
        <div v-show="elementVisible" class="ts-image-container">
          <div class="ts-image ts-image__1">
            <img src="/images/signup/burgur.png" alt="..." />
          </div>
          <div class="ts-image ts-image__2">
            <img src="/images/signup/donut.png" alt="..." />
          </div>
          <div class="ts-image ts-image__3">
            <div class="ts-inner-img">
              <img src="/images/signup/emoji2.png" alt="..." />
            </div>
          </div>
          <div class="ts-image ts-image__4">
            <img src="/images/signup/emoji.png" alt="..." />
          </div>
          <div class="ts-image ts-image__5">
            <div class="ts-rotate">
              <img src="/images/Home/heart.png" alt="..." />
            </div>
          </div>
        </div>
        <div class="row row-cols-lg-2 align-items-center gap-5 gap-lg-0">
          <div class="order-1 order-lg-0">
            <header class="ts-creator-form-hero__header ts-text-gray-2">
              <img
                height="43"
                class="mb-03"
                src="/icons/quote-close-svgrepo-com.svg"
                alt="..."
              />
              <h1 class="ts-fs-2-b fw-bold mb-04 mb-lg-07">
                My go to platform for collaborations
              </h1>
              <p class="ts-fs-5 fw-light mb-05 mb-lg-07">
                Their dedicated and responsive team ensures a seamless
                experience, exceeding my expectations!
              </p>

              <div class="ts-text-gray-1 d-flex align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="..."
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-1a fw-medium mb-1">
                    Emily Watson
                  </h5>
                  <div
                    class="ts-social-links d-flex gap-2 gap-sm-3 justify-content-start"
                  >
                    <a class="ts-text-gray-4" href="#"
                      ><i class="bi bi-instagram"></i></a
                    ><a class="ts-text-gray-4" href="#"
                      ><i class="bi bi-twitch"></i></a
                    ><a class="ts-text-gray-4" href="#"
                      ><i class="bi bi-twitter"></i
                    ></a>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div class="order-0 order-lg-1">
            <div class="ts-contact">
              <div class="ts-contact__content">
                <div class="ts-contact-form-container ts-shadow-1">
                  <div class="mb-3">
                    <swiper
                      :slidesPerView="1"
                      :spaceBetween="16"
                      :pagination="{
                        clickable: true,
                        el: '.ts-creator-form-swiper',
                      }"
                      :modules="modules"
                      class="ts-creator-swiper mySwiper d-flex"
                      :class="isLastSlide ? 'd-none' : ''"
                      ref="tsCreatorSwiper"
                      @swiper="onSwiper"
                      @slideChange="handleSlideChange"
                    >
                      <swiper-slide>
                        <Form1 />
                      </swiper-slide>
                      <swiper-slide>
                        <Form2 />
                      </swiper-slide>
                      <swiper-slide class="h-auto">
                        <Form3 />
                      </swiper-slide>
                      <swiper-slide>
                        <Form4 />
                      </swiper-slide>
                    </swiper>

                    <div
                      class="ts-congratulation-wrapper"
                      :class="isLastSlide ? '' : 'd-none'"
                    >
                      <Congratulation />
                    </div>

                    <div
                      class="ts-creator-form-swiper ts-swiper-pagination-2 d-flex justify-content-center"
                      :class="isLastSlide ? 'invisible' : ''"
                    ></div>
                  </div>
                  <button
                    class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill"
                    @click="handleSlideNext"
                    :class="
                      (btnText === 'Continue'
                        ? 'ts-btn-primary'
                        : 'ts-btn-secondary',
                      isLastSlide ? 'd-none' : '')
                    "
                  >
                    {{ btnText }}
                  </button>
                  <button
                    class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-secondary w-100 rounded-pill"
                    @click="handleCongratulations"
                    :class="isLastSlide ? '' : 'd-none'"
                  >
                    Done
                  </button>
                  <h3
                    class="ts-fs-7-ii text-center ts-already-signup fw-normal mb-0"
                    :class="isLastSlide ? 'invisible' : ''"
                  >
                    Already have an account?
                    <a
                      class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                      href="#"
                    >
                      Sign in</a
                    >
                  </h3>
                </div>
                <h3
                  class="ts-fs-7-ii text-center ts-already-signup-2 fw-normal"
                >
                  Are you a brand?
                  <router-link
                    class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                    to="/brands-signup"
                  >
                    Sign up here
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Modal -->
  <div
    class="modal fade"
    id="addSocialMedia"
    tabindex="-1"
    aria-labelledby="addSocialMediaLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content ts-add-social-media-modal-content">
        <div class="modal-body p-0">
          <form class="ts-contact">
            <div
              class="ts-social-media-handle-group mb-5"
              data-bs-toggle="modal"
              data-bs-target="#addSocialMedia"
            >
              <div class="ts-social-media-handle ts-social-media-handle--lg">
                <Icon name="instagram-lg" />
              </div>
              <p class="ts-fs-7 mb-0">Instagram</p>
            </div>

            <div class="mb-3">
              <input
                type="text"
                class="form-control form-control-lg rounded-pill"
                id="username"
                placeholder="@username"
              />
            </div>
          </form>
          <button
            class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill"
            data-bs-toggle="modal"
            data-bs-target="#addSocialMediaConfirm"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="addSocialMediaConfirm"
    tabindex="-2"
    aria-labelledby="addSocialMediaConfirmLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content ts-add-social-media-modal-content">
        <div class="modal-body p-0">
          <div class="d-flex justify-content-center align-item-center mb-06">
            <div class="ts-copy-code d-flex">
              <p class="ts-text-gray-7 fw-semibold mb-0 lh-1">534655</p>
              <button class="btn border-0 p-0 lh-1">
                <Icon name="copy" />
              </button>
            </div>
          </div>

          <p class="text-center mb-07">
            Message the above code to
            <a href="#" class="ts-text-primary-1 fw-bold text-decoration-none">
              @alist.ae
            </a>
            using your page on Instagram to verify the ownership of your
            account.
          </p>

          <a
            class="ts-btn ts-btn--sm ts-btn-secondary w-100 rounded-pill mb-3 text-center"
            href="https://instagram.com/"
            target="_blank"
          >
            Open instagram
          </a>
          <button
            class="ts-btn ts-btn--sm ts-btn-primary w-100 rounded-pill"
            data-bs-dismiss="modal"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "../../assets/icons/index.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

import Form1 from "./creator/Form1.vue";
import Form2 from "./creator/Form2.vue";
import Form3 from "./creator/Form3.vue";
import Form4 from "./creator/Form4.vue";
import Congratulation from "./common/Congratulation.vue";

export default {
  name: "CreatorSignupHero",
  components: {
    Form1,
    Form2,
    Form3,
    Form4,
    Congratulation,
    Swiper,
    SwiperSlide,
    Icon,
  },

  data() {
    return {
      btnText: "Continue",
      isLastSlide: false,
      isLastSlideActive: false,
      elementVisible: false,
    };
  },

  created() {
    setTimeout(() => (this.elementVisible = true), 500);
  },

  methods: {
    onSubmit() {
      console.log("Working");
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleSlideChange() {
      this.isLastSlideActive = false;
      this.btnText = "Continue";
      if (this.swiper.isEnd || this.swiper.activeIndex === 3) {
        this.btnText = "Done";
      }
    },
    handleSlideNext() {
      this.isLastSlide = false;
      this.swiper.slideNext();
      this.btnText = "Continue";

      if (this.isLastSlideActive) {
        this.isLastSlide = true;
      }
      if (this.swiper.isEnd) {
        this.btnText = "Done";
        this.isLastSlideActive = true;
      }
    },

    handleCongratulations() {
      this.swiper.slideTo(0);
      this.isLastSlide = false;
    },
  },

  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
};
</script>
<style lang="scss" scoped>
.ts-bg-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -7;
  @media (max-width: 1500px) {
    overflow: hidden;
  }
}

.ts-social-links {
  font-size: clamp(21px, 3.5vw, 23px);
  svg {
    width: 20px;
  }
}
.ts-social-media-handle {
  border-radius: 1rem;
  &--lg {
    width: 96px;
    height: 96px;
  }
  #instagram {
    width: 55px !important;
    height: 55px !important;
  }
}

.ts-copy-code {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  padding: 11px 25px 11px 35px;
  border-radius: 8px;
  gap: 1rem;
}
.ts-add-social-media-modal-content {
  max-width: 405px;
  padding: 57px 36px;
  border-radius: 34px;
}

@media (max-width: 475px) {
  .ts-creator-form-hero__header {
    max-width: 338px;
    margin: auto;
  }
}
.ts-form-hero {
  // padding-block: 14rem 6rem;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  isolation: isolate;
  padding-block: 6.5625rem 3.5rem;
  overflow: hidden;
  min-height: 100vh;

  @media (min-width: 992px) {
    padding-block: 11rem 6rem;
  }

  &__content {
    max-width: 1224px;
    margin: auto;
    @media (min-width: 992px) {
      position: relative;
    }
  }

  .ts-bg-1 {
    position: absolute;
    bottom: 30%;
    left: 0;
    transform: translate(-26%, -119%);
    width: clamp(800px, 140vw, 1302px) !important;
    z-index: -3;

    @media (max-width: 991px) {
      transform: translate(-38%, 17%);
    }
  }
  .ts-bg-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-62%, 61%);
    width: clamp(800px, 120vw, 1060px) !important;
    z-index: -3;
    @media (max-width: 991px) {
      transform: translate(-79%, 61%);
    }
  }
  .ts-bg-3 {
    position: absolute;
    bottom: 30%;
    left: 0;
    transform: translate(-40%, -64%);
    width: clamp(800px, 140vw, 1302px) !important;
    z-index: -2;
    @media (max-width: 991px) {
      transform: translate(-38%, 17%);
    }
  }

  .ts-hash {
    position: absolute;
    left: 0%;
    top: 12%;
    width: clamp(320px, 40vw, 500px);
    z-index: -2;
    transform: translateX(-50%);
    opacity: 85%;
    @media (max-width: 991px) {
      top: auto;
      bottom: -29px;
      left: 3%;
      opacity: 70%;
    }
  }
}

.ts-contact {
  margin: 0 !important;
}

.ts-image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  @media (max-width: 991px) {
    overflow: hidden;
  }
}
.ts-image {
  img {
    position: absolute !important;
  }

  &__1 {
    img {
      width: clamp(80px, 8vw, 120px) !important;
      animation: floating 2.5s ease-in-out infinite;
    }
  }

  &__2 {
    img {
      width: clamp(90px, 12vw, 140px) !important;
      animation: floating 3s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(30deg);
    }
  }

  &__3 {
    img {
      width: clamp(80px, 10vw, 120px) !important;
      animation: floating 3.5s ease-in-out infinite;
    }
  }

  &__4 {
    img {
      width: clamp(80px, 11vw, 110px) !important;
      animation: floating 4s ease-in-out infinite;
    }
  }

  &__5 {
    img {
      width: clamp(80px, 12vw, 140px) !important;
      animation: floating 4s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(-30deg);
    }
  }

  &__1 {
    img {
      left: 43%;
      top: 10%;
    }
  }
  &__2 {
    img {
      top: 87%;
      right: 0;

      @media (min-width: 1300px) {
        top: 82%;
        right: -7%;
      }
    }
  }

  &__3 {
    img {
      top: -7%;
      right: 0;

      @media (min-width: 1300px) {
        top: 4%;
        right: -6%;
      }
    }
  }

  &__4 {
    img {
      right: 52% !important;
      top: 72%;
      transform: translate(100%, -100%);
    }
  }

  &__5 {
    img {
      top: 100%;
      left: 0%;
      transform: translate(-260%, 181%) !important;
    }
  }
  @media (max-width: 1400px) and (min-width: 1201px) {
    &__5 {
      img {
        transform: translate(-193%, 188%) !important;
      }
    }
  }
  @media (max-width: 1200px) and (min-width: 992px) {
    &__5 {
      img {
        transform: translate(-206%, 210%) !important;
      }
    }
  }

  @media (max-width: 991px) {
    &__2,
    &__3,
    &__4 {
      display: none;
    }

    &__1 {
      img {
        left: auto !important;
        top: 66%;
        right: 16px;
        transform: rotate(45deg) !important;
      }
    }
    &__5 {
      position: absolute;
      top: 87%;
      left: auto;
      right: 61px;
      .ts-rotate {
        transform: rotate(45deg);
      }
      img {
        left: 80%;
        top: 65%;
        transform: translate(0, 0) !important;
      }
    }
  }
}

@keyframes floating {
  0% {
    transform: rotate(-5deg) translatey(0px);
  }

  50% {
    transform: rotate(-3deg) translatey(7px);
  }

  100% {
    transform: rotate(-5deg) translatey(0px);
  }
}

.ts-congratulation-wrapper {
  min-height: 442px;
}
</style>
