<template>
  <section class="ts-hero d-flex align-items-center">
    <div class="w-100">
      <div class="ts-container-fluid container-fluid mb-1 mb-lg-0">
        <div class="ts-hero__content text-center text-lg-start">
          <h1 class="ts-fs-1 ts-text-gray-5 fw-bold mb-06 mb-lg-07">
            The leading platform for aspiring Influencers & Creators
          </h1>
          <p class="ts-hero__desc ts-fs-5 fw-light mb-07-ii">
            Let hundreds of creators endorse your brand, giving it the exposure
            it deserves.
          </p>
          <router-link
            class="ts-btn ts-btn-primary rounded-pill px-3"
            to="/brands-signup"
            >Get a free demo</router-link
          >
        </div>
      </div>
      <img class="w-100 d-lg-none" src="/images/Brand/5-sm.png" alt="..." />
    </div>
  </section>
</template>

<script>
export default {
  name: "TsHero",
};
</script>

<style scoped lang="scss">
.ts-hero {
  background-color: #f9f9f9 !important;
  background-size: 100% auto;
  background-position: right bottom !important;
  background-image: url("../../../public/images/Brand/brands__1440p.jpg");

  @media (max-width: 991px) {
    padding-bottom: 0;
    background-size: 130% auto !important;
  }
  @media (max-width: 575px) {
    background: #f9f9f9 !important;
    padding-bottom: 0 !important;
  }

  @media (min-width: 1920px) {
    background-image: url("../../../public/images/Brand/ipad.png");
    background-size: 80% auto !important;
    background-position: 130% bottom !important;
  }
  @media (min-width: 2400px) {
    background-size: 1700px auto !important;
    background-position: 75% bottom !important;
  }

  &__content {
    max-width: 590px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
    @media (max-width: 420px) {
      max-width: 330px;
      margin: auto;
    }
  }

  &__desc {
    max-width: 540px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
}
</style>
