<template>
  <section class="ts-how-it-works">
    <h1 class="ts-fs-1 text-center ts-text-gray-5 fw-bold mb-08 mb-lg-13">
      How it works
    </h1>
    <div class="ts-how-it-works__content">
      <div class="ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
      <div
        class="ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
      ></div>
      <div class="row align-items-center">
        <div class="col-lg-4 d-none d-xl-block text-center">
          <img
            class="ts-phone-img w-100"
            src="/images/Creators/phone.png"
            alt="..."
          />
        </div>
        <div class="col-xl-8">
          <div class="row row-cols-md-2">
            <div class="mb-4">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  src="/icons/burger.svg"
                  alt="..."
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">1</span>. Choose
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  Take your pick from a fantastic selection of offers and
                  experiences that match your style and interests. Find exactly
                  what you're looking for and have a blast!
                </h5>
              </div>
            </div>
            <div class="mb-4">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  src="/icons/redeam.svg"
                  alt="..."
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">2</span>. Redeem
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  Once you've made your choice, it's time to claim your offer!
                  Just follow the simple instructions provided and get ready to
                  enjoy the perks from the participating brands.
                </h5>
              </div>
            </div>
            <div class="mb-4 mb-md-0">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  src="/icons/camera.svg"
                  alt="..."
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">3</span>. Create
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  Showcase your talent as an influencer by creating amazing
                  content featuring the products or services you receive, and
                  tell your unique story to captivate your audience.
                </h5>
              </div>
            </div>
            <div class="mb-4 mb-md-0">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  src="/icons/heart.svg"
                  alt="..."
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">4</span>. Share
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  Share your amazing content with your followers, and then
                  upload screenshots of your content to the alist app for
                  review. Make sure to provide proof of your work on the app for
                  validation.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowItWorks",
};
</script>

<style scoped lang="scss">
.ts-phone-img {
  max-width: 368px;
}

.ts-creator-card__img {
  width: clamp(1.9375rem, 5vw, 3.125rem);
}

.ts-how-it-works__content {
  position: relative;
}

.ts-bg-radius--secondary {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-30%, -40%);
  width: 80% !important;
  height: 80% !important;
  padding-top: 80% !important;
  z-index: -2;
}

.ts-bg-radius--primary-secondary--mix {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(1%, -26%);
  width: 805px !important;
  height: 805px !important;
  padding-top: 805px !important;
  z-index: -1;
  opacity: 90%;
}

@media (max-width: 991px) {
  .ts-bg-radius--secondary {
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120% !important;
    height: 100% !important;
    padding-top: auto !important;
    z-index: -2;
  }

  .ts-bg-radius--primary-secondary--mix {
    transform: translate(1%, -42%);
  }
}
</style>
