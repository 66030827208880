import { createRouter, createWebHistory } from "vue-router";

// Pages
import Home from "../Pages/Home.vue";
import Blog from "../Pages/Blog.vue";
import BrandsSignup from "../Pages/BrandsSignup.vue";
import BlogPost from "../Pages/BlogPost.vue";
import CreatorSignup from "../Pages/CreatorSignup.vue";
import Contact from "../Pages/Contact.vue";
import Creators from "../Pages/Creators.vue";
import Career from "../Pages/Career.vue";
import Brands from "../Pages/Brands.vue";
import Faq from "../Pages/Faq.vue";
import Team from "../Pages/Team.vue";

// const Home = { template: "<h1 class='my-5'>Home</h1>" };
const PageNotFound = { template: "<h1 class='my-5'>Page Not Found</h1>" };

const routes = [
  //   { path: "/", component: Home },
  //   { path: "/about", component: About },
  // { path: "/temp", component: Home },

  { path: "/blog", component: Blog },
  { path: "/blog-post", component: BlogPost },
  { path: "/brands-signup", component: BrandsSignup },
  { path: "/brands", component: Brands },
  { path: "/creator-signup", component: CreatorSignup },
  { path: "/creators", component: Creators },
  { path: "/career", component: Career },
  { path: "/contact", component: Contact },
  { path: "/faq", component: Faq },
  { path: "/team", component: Team },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
  { path: "/", component: Home },
];

const router = createRouter({
  // scrollBehavior() {
  //   // always scroll to top
  //   return { top: 0 };
  // },
  history: createWebHistory(),
  // mode:'history',
  routes, // short for `routes: routes`
  // scrollBehavior() {
  //   document.getElementById("app").scrollIntoView({ behavior: "auto" });
  // },
});

router.beforeEach(function (to, from, next) {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 1);
  next();
});
export default router;
