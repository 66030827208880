<template>
  <img
    class="ts-team-hero-img w-100 mb-07 mb-lg-10"
    src="/images/Team/office.jpg"
    alt="..."
  />

  <section>
    <div class="ts-container-fluid container-fluid">
      <header class="ts-team__header text-center mb-07 mb-lg-12">
        <h1 class="ts-fs-1 ts-text-gray-5 fw-light text-center mb-04 mb-lg-07">
          Meet The <span class="fw-bold"> Team</span>
        </h1>

        <p class="ts-fs-5 fw-light mb-05 mb-lg-07">
          We're a tight-knit tribe of creative minds and trendsetters, dedicated
          to revolutionizing the world of influence
        </p>
      </header>

      <div class="ts-team-cards-group">
        <img
          class="ts-bg"
          src="/images/Contact/contact-form-bg.svg"
          alt="..."
        />
        <div class="mb-07 mb-lg-08 d-md-none">
          <select
            class="form-select ts-form-select rounded-pill py-2"
            aria-label="Default select example"
          >
            <option selected>All</option>
            <option value="1">Tips and Tricks</option>
            <option value="2">Content Creation</option>
            <option value="3">Influencer Marketing</option>
          </select>
        </div>

        <div
          class="d-none d-md-flex justify-content-center flex-wrap gap-2 mb-14"
        >
          <button
            class="ts-btn-tags"
            v-for="tag in tags"
            :key="tag"
            :data-attribute="tag"
            :class="{ 'ts-btn-tags--active': isActive === tag }"
            @click="toggleClass"
          >
            {{ tag }}
          </button>
        </div>
        <div class="ts-team-card-group mb-5">
          <div v-for="(card, index) in visibleCards" :key="index">
            <TeamCard :name="card.name" :department="card.department" />
          </div>
        </div>
        <div class="text-center">
          <button
            v-if="!hideShowMoreButton"
            @click="showMore"
            class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill px5"
          >
            Show More
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TeamCard from "../components/Team/Card.vue";

export default {
  name: "TeamPage",
  components: {
    TeamCard,
  },

  beforeMount() {
    // Add classes to Navbar
    const navBarCustomClasses = "ts-bg-white";
    this.$emit("data-navBarCustomClasses", navBarCustomClasses);
  },

  beforeUnmount() {
    // Remove classes to Navbar
    const navBarCustomClasses = "";
    this.$emit("data-navBarCustomClasses", navBarCustomClasses);
  },

  data() {
    return {
      isActive: "All",
      tags: [
        "All",
        "Creative",
        "Marketing & Sales",
        "Business",
        "PR",
        "Accounting",
        "Client Services",
        "IT",
      ],

      cards: [
        { id: 1, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 2, name: "Yacob", department: "Art Director" },
        { id: 3, name: "Emily Collins", department: "Social Media Manager" },
        {
          id: 4,
          name: "Isabella Martinez",
          department: "Public Relations Manager",
        },
        { id: 5, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 6, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 7, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 8, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 9, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 10, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 11, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 12, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 13, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 14, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 15, name: "Parham Ramezani", department: "Founder & CEO" },
        { id: 16, name: "Parham Ramezani", department: "Founder & CEO" },
      ],

      itemsToShow: 8, // Initial number of items to show
      hideShowMoreButton: false,
    };
  },

  computed: {
    visibleCards() {
      return this.cards.slice(0, this.itemsToShow);
    },
  },

  methods: {
    toggleClass(event) {
      // Get the clicked element
      const clickedElement = event.target;
      // Get the data attribute value
      const dataAttributeValue = clickedElement.getAttribute("data-attribute");

      // change the active button State

      this.isActive = dataAttributeValue;
    },

    showMore() {
      if (this.itemsToShow >= this.cards.length) {
        this.hideShowMoreButton = true;
      }
      this.itemsToShow += 4; // Increase the number of items to show
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  padding-top: 77px !important;
}

.ts-team-card-group {
  display: grid;
  row-gap: clamp(2.3125rem, 4vw, 3rem);
  column-gap: clamp(2.3125rem, 4vw, 3.1875rem);
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1201px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
// .navbar {
//   background-color: white !important;
// }

.ts-team-hero-img {
  min-height: 593px;
  object-fit: cover;
  @media (max-width: 991px) {
    min-height: 360px;
    object-fit: cover;
  }
}

.ts-team__header {
  max-width: 980px;
  margin-inline: auto;
}

.ts-team-cards-group {
  position: relative;
  max-width: 1228px;
  margin: auto;
  margin-bottom: 150px;
  isolation: isolate;
  .ts-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(800px, 80vw, 900px);
    z-index: -1;
  }
}

.ts-btn-tags {
  font-size: 23px;
  border-radius: 50rem;
  border: none;
  padding: 10px 1rem 13px;
  line-height: 100%;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  border: 1px solid #00a4b600;

  &--active {
    // background-color: #00A4B6;
    border: 1px solid #00a4b6;
    color: #00a4b6;
    padding-inline: 2.5rem;
  }
}
</style>
