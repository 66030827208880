<template>
  <Hero />
</template>
<script>
import Hero from "../components/Form/Hero.vue";

export default {
  name: "BrandsSignup",
  components: {
    Hero,
  },
  beforeMount() {
    // Add classes to Navbar
    const hideFooter = false;
    this.$emit("data-hideFooter", hideFooter);
  },

  beforeUnmount() {
    // Remove classes to Navbar
    const hideFooter = false;
    this.$emit("data-hideFooter", hideFooter);
  },
};
</script>
<style lang="scss" scoped></style>
