<template>
  <section class="ts-harness-power text-center text-lg-start">
    <div class="row align-items-center">
      <div class="col-lg-6 order-1 order-lg-0 position-relative">
        <img class="w-100" src="/images/Brand/1.png" alt="..." />
        <img
          class="ts-hash d-none d-xl-block"
          src="/images/hash.svg"
          alt="..."
        />
      </div>
      <div class="col-lg-6 order-0 order-lg-1">
        <h1 class="ts-fs-1 ts-text-gray-5 fw-normal mb-05">
          <span class="ts-text-gray-5 fw-bold"> Harness the power </span>
          <br />
          of influencer marketing
        </h1>
        <p class="ts-fs-5 ts-text-gray-1 fw-light mb-03 mb-lg-07">
          By leveraging the influence and reach of social media creators.
          Amplify your brand's message, drive conversions, and unlock the true
          potential of influencer marketing.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HarnessPower",
};
</script>

<style scoped lang="scss">
.ts-hash {
  position: absolute;
  right: -4%;
  bottom: -4%;
  width: clamp(90px, 8vw, 128px);
}
</style>
