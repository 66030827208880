<template>
  <div>
    <div>
      <header
        class="ts-contact-form-container__header ts-text-gray-4 text-center"
      >
        <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
          Let's get you started
        </h1>
        <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-07">
          Please fill out your details
        </p>
      </header>

      <form>
        <div class="row row-cols-2 px-2 mb-2">
          <div class="px-1">
            <input
              type="text"
              class="form-control form-control-lg rounded-pill"
              id="firstName"
              placeholder="First Name"
            />
          </div>
          <div class="px-1">
            <input
              type="text"
              class="form-control form-control-lg rounded-pill"
              id="lastName"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div class="mb-2">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="Business Name"
            aria-describedby="Business NameHelp"
            placeholder="Business Name"
          />
        </div>
        <div class="mb-2">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="BusinessInstagramHelp"
            aria-describedby="BusinessInstagramHelp"
            placeholder="Business Instagram"
          />
        </div>

        <div class="ts-form-control-group d-flex mb-2">
          <div class="">
            <select
              class="form-select form-select-lg rounded-pill ts-phone"
              aria-label="Default select example"
            >
              <option selected>+971</option>
              <option value="1">+972</option>
              <option value="2">+973</option>
              <option value="3">+974</option>
            </select>
          </div>
          <div class="flex-fill">
            <input
              type="tel"
              class="form-control form-control-lg rounded-pill"
              id="tsPhone"
              aria-describedby="PhoneHelp"
              placeholder="Phone"
            />
          </div>
        </div>

        <div class="mb-0">
          <select
            class="form-select form-select-lg rounded-pill ts-phone"
            aria-label="Default select example"
          >
            <option selected>Select location</option>
            <option value="1">Location 1</option>
            <option value="2">Location 2</option>
            <option value="3">Location 3</option>
          </select>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "BrandsForm1",
};
</script>
<style lang="scss" scoped></style>
