<template>
  <section class="ts-brands-logos">
    <slot></slot>
    <div class="ts-brands-logos__content position-relative overflow-hidden">
      <div class="loop-container">
        <div>
          <img height="60" src="/images/BrandLogos/1.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/2.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/3.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/4.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/5.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/6.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/7.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/8.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/9.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/10.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/11.png" alt="..." />
        </div>
        <div>
          <img height="60" src="/images/BrandLogos/12.png" alt="..." />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BrandsLogos",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.ts-brands-logos {
  img {
    height: 60px;

    @media (max-width: 991px) {
      height: 50px;
    }
  }

  &__content {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100px;
      height: 100%;
      z-index: 10;
      pointer-events: none;
    }

    &::before {
      left: 0;
      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
    }

    &::after {
      right: 0;

      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
    }
  }
}

.loop-container {
  overflow: hidden;
  display: flex;
  width: calc(2400px + 22rem);
  margin: 0 auto;
  gap: clamp(0.5rem, 4vw, 2rem);
}

.loop-container div {
  min-width: 200px;
  animation: slide 40s linear infinite;
  text-align: center;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1500px, 0, 0);
  }
}
</style>
